<script>
import GlobalVue from "@helper/Global.vue";
import BaseVue from "@frontend/Base.vue";
import Gen from "@helper/Gen";
export default {
  extends: GlobalVue,
  data() {
    return {
      slider: [],
      autoplay: "",
      delay: ""
    };
  },
  async mounted() {
    // await this.ready();
    this.apiGetSliderHome();
  },
  methods: {
    apiGetSliderHome() {
      Gen.apirest("/home-slider", {}, (err, resp) => {
        if (err) console.log(err);
        this.slider = resp.data;
        this.autoplay = resp.autoplay;
        this.delay = resp.delay;
      });
    },
  },
};
</script>
<template>
  <div
    id="slider"
    class="slider-element swiper_wrapper min-vh-100"
    v-if="slider.length > 0"
    :data-autoplay="autoplay === 'Y' ? delay : ''"
    style="max-height: 20vh !important"
  >
    <div v-if="!isMobile" class="slider-inner oneImg">
      <div class="swiper-container swiper-parent">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(v, k) in slider" :key="k">
            <div class="container">
              <div class="slider-round-caption">
                <div class="round-caption-content text-center">
                  <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8">
                      <h2
                        class="slider__title text-center mb-4"
                        v-html="v.as_title"
                        :style="'color: ' + v.as_color_title + ';'"
                        itemprop="alternativeHeadline"
                      ></h2>
                    </div>
                    <div class="col-md-9 col-lg-5" itemscope itemtype="https://schema.org/School">
                      <p
                        class="slider__caption d-none d-md-block"
                        v-html="v.as_desc"
                        :style="'color: ' + v.as_color_description + ';'"
                        itemprop="slogan"
                      ></p>
                      <a
                        v-if="v.as_link"
                        :href="v.as_link"
                        :target="v.as_link_target"
                        class="slider__cta cta cta--primary"
                        v-html="v.as_button_name"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="swiper-slide-bg"
              :style="'background-image:url(' + uploader(v.as_img) + ')'"
              :data-mobile-hero="uploader(v.as_img_mobile)"
            ><span class="d-none position-absolute">{{v.as_alt_img}}</span></div>
          </div>
        </div>
        <div v-if="slider.length > 1">
          <div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
          <div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
        </div>
      </div>
      <a
        href="#"
        data-scrollto="#content"
        data-offset="100"
        class="one-page-arrow dark"
        ><i class="icon-angle-down infinite animated fadeInDown"></i
      ></a>
    </div>
    <div v-else class="slider-inner oneImg">
      <div class="swiper-container swiper-parent">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(v, k) in slider" :key="k">
            <div class="container">
              <div class="slider-round-caption">
                <div class="round-caption-content text-center">
                  <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8">
                      <h2
                        class="slider__title text-center mb-4"
                        v-html="v.as_title"
                        :style="'color: ' + v.as_color_title + ';'"
                      ></h2>
                    </div>
                    <div class="col-md-9 col-lg-5">
                      <p
                        class="slider__caption d-md-block"
                        v-html="v.as_desc"
                        :style="'color: ' + v.as_color_description + ';'"
                      ></p>
                      <a
                        v-if="v.as_link"
                        :href="v.as_link"
                        :target="v.as_link_target"
                        class="slider__cta cta cta--primary"
                        v-html="v.as_button_name"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="swiper-slide-bg"
              :style="'background-image:url(' + uploader(v.as_img_mobile) + ')'"
              :data-mobile-hero="uploader(v.as_img_mobile)"
            ><span class="d-none position-absolute">{{v.as_alt_img}}</span></div>
          </div>
        </div>
        <div v-if="slider.length > 1">
          <div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
          <div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
        </div>
      </div>
      <a
        href="#"
        data-scrollto="#content"
        data-offset="100"
        class="one-page-arrow dark"
        ><i class="icon-angle-down infinite animated fadeInDown"></i
      ></a>
    </div>
  </div>
</template>
